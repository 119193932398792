
.root {
    flex-direction: column;
    display: flex;
    gap: var(--spacing-4) var(--spacing-6);


    @media --sm {
        flex-direction: row;
    }

}

.table {
    border-collapse: collapse;
}

.listLabel {
    margin-right: var(--spacing-2);
}