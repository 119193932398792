.root {
    padding: var(--spacing-6);
    border: 1px solid var(--color-line);
    background-color: var(--color-line);
    display: grid;
    gap: var(--spacing-4) var(--spacing-8);

    @media --sm {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
}

.formWrapper {
    @media --sm {
        display: flex;
        justify-content: center;
    }
}