.inputWrapper {
    display: flex;
    background-color: var(--color-neutral-white);
    max-width: 380px;
    align-items: center;
    border: 1px solid var(--color-neutral-700);
    border-radius: var(--border-radius-small);
}

.input {
    appearance: none;
    border: none;
    width: 100%;
    padding: var(--spacing-1) var(--spacing-2);
    text-transform: uppercase;

    &:focus {
        outline: none;
    }
}

.submitButton {
    &:focus {
        outline: none;
    }
}

.countryCode {
    padding: var(--spacing-2);
    background-color: var(--color-neutral-700);
}

.buttonPlaceholder {
    display: inline-block;
    width: 50px;
}